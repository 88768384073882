import { j as e } from "./jsx-runtime-D0AQ1Mn7.js";
import { B as m } from "./Button-BOVHAiPs.js";
import { D as a } from "./Dialog.component-fr4P9TXE.js";
import { I as c } from "./Icon-CtH7buGH.js";
import { S as l } from "./Stack-BZqOy47V.js";
import { T as n } from "./Text-B4fIcnEL.js";
import { c as r } from "./utils-CJ9afRe1.js";
const g = ({ severity: t = "error", ...s }) => {
  let i = "BigExclamationDestructive";
  return t === "info" ? i = "BigExclamation" : t === "warning" && (i = "BigExclamationWarning"), /* @__PURE__ */ e.jsx(
    a,
    {
      open: !0,
      onOpenChange: s.onOpenChange,
      children: /* @__PURE__ */ e.jsx(a.Content, { className: "max-w-[95%] rounded-md sm:max-w-md", children: /* @__PURE__ */ e.jsxs(
        l,
        {
          className: r("px-3 bg-white w-full mx-auto pb-6 pt-2 rounded-md text-center", {
            "pb-14": !s.onAskMyLenderClick
          }),
          items: "center",
          justify: "center",
          space: "xl",
          children: [
            /* @__PURE__ */ e.jsx(l, { justify: "end", row: !0, className: "w-full", children: /* @__PURE__ */ e.jsx(a.Close, { asChild: !0, children: /* @__PURE__ */ e.jsx(
              m,
              {
                circle: !0,
                className: "w-6 h-6 p-0 items-center justify-center inline-flex",
                size: "sm",
                variant: "secondary",
                children: /* @__PURE__ */ e.jsx(c, { name: "Cancel", width: 18, height: 18, strokeWidth: 1.5 })
              }
            ) }) }),
            /* @__PURE__ */ e.jsx(
              c,
              {
                name: i,
                className: "text-black-10",
                width: 64,
                height: 64
              }
            ),
            s.fileName && !s.message && /* @__PURE__ */ e.jsxs(n, { size: "sm", className: "px-10", children: [
              "You do not have access to view ",
              s.fileName,
              "."
            ] }),
            !s.fileName && !s.message && /* @__PURE__ */ e.jsx(n, { size: "sm", className: "px-10", children: "You do not have access to view this item." }),
            s.message && /* @__PURE__ */ e.jsx(n, { size: "sm", className: "px-10", children: s.message }),
            s.onAskMyLenderClick && /* @__PURE__ */ e.jsx(
              m,
              {
                onClick: s.onAskMyLenderClick,
                size: "sm",
                variant: "link",
                className: "underline cursor-pointer underline-offset-2",
                children: "Ask My Lender"
              }
            )
          ]
        }
      ) })
    }
  );
};
export {
  g as default
};
